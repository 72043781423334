import React from "react";

export default function TeamTwo() {
  return (
    <>
      <section id="team" className="team-member-section py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-heading text-center mb-5">
                <h2>Our Lovely Team</h2>
                <p>
                  At the heart of our operations lies a small yet immensely
                  dedicated team, tirelessly endeavoring day and night to ensure
                  that every aspect of your experience is nothing short of
                  exceptional.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/team-4.jpg"
                    alt="Team Members"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Ashish Gupta</h5>
                  <h6>Product Manager</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-github"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/team-1.jpg"
                    alt="Team Members"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Abdullah Arif</h5>
                  <h6>Frontend Developer</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-github"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/team-2.jpg"
                    alt="Team Members"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Muhammad Aqeel</h5>
                  <h6>Backend Developer</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-github"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/team-3.jpg"
                    alt="Team Members"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Lara Ditta</h5>
                  <h6>UI Designer</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-github"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
