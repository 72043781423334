import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";

export default function VideoPromoTwo() {
  const [isOpen, setOpen] = useState(false);
  const VideoButton = ({ videoId, label }) => {
    return (
      <div className="col-12 col-md-4">
        <Link
          to="#"
          className="popup-youtube video-play-icon d-inline-block mb-2"
        >
          <span
            className="ti-control-play"
            onClick={() => setOpen(videoId)}
          ></span>{" "}
        </Link>
        <h5 className="mt-4 text-white mb-3">{label}</h5>
      </div>
    );
  };
  return (
    <>
      <section
        id="download"
        className="video-promo ptb-100 background-img"
        style={{
          background:
            "url('assets/img/video-bg.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="video-promo-content mt-4 text-center">
                <div className="row">
                  <VideoButton
                    videoId="zIgivo3EYsg"
                    label="World's 1st Opinion poll Contests App"
                  />
                  <VideoButton
                    videoId="-6_PWqyhao8"
                    label="Strategic opinions, real winnings!"
                  />
                  <VideoButton
                    videoId="Y79ymqN_br4"
                    label="Play Contests using your IQ and EQ to win exciting rewards!"
                  />
                </div>
                <div className="download-btn mt-5">
                  <a
                    className="btn google-play-btn mr-3"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.app.qunions&hl=en&gl=US"
                      )
                    }
                  >
                    <span className="ti-android"></span> Google Play
                  </a>
                  <a
                    className="btn app-store-btn color-white mr-3 bg-white"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/apple-store/id1668454446"
                      )
                    }
                  >
                    <span className="ti-apple"></span> App Store
                  </a>
                  <a
                    className="btn app-store-btn"
                    onClick={() =>
                      window.open(
                        "https://expo.dev/artifacts/eas/4GYgutEdEP9dqvFuhE8RE9.apk"
                      )
                    }
                    style={{
                      color: "#FFF",
                    }}
                  >
                    <span className="ti-android"></span> APK Download
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            isOpen={typeof isOpen == typeof ""}
            videoId={isOpen}
            onClose={() => setOpen(false)}
            ratio="9:16"
            autoplay
          />
        </div>
      </section>
    </>
  );
}
