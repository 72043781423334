import React from "react";

export default function PromoTwo() {
  const formats = [
    {
      title: "Trades",
      des: "Engage in post-event discussions or otherwise to earn profits from your strategic opinions.",
      src: "./assets/img/game_formats/trades.png",
    },
    {
      title: "Events",
      des: "Engage with the larger audience on a particular theme to win Mega prizes.",
      src: "./assets/img/game_formats/events.png",
    },
    {
      title: "Game",
      des: "Play with friends, family and beyond on any occasion for fun!",
      src: "./assets/img/game_formats/game.png",
    },
  ];
  return (
    <>
      <section className="promo-section mt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading mb-5">
                <h2>Qunions Format</h2>
                <p className="mb-2 lh-190">
                  There are 3 different Qunions format. For each format,
                  strategize as per their winning objective to win.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {formats.map((format) => (
              <div
                className="col-lg-4 col-sm-6 mb-lg-0 d-flex mb-3"
                style={{ cursor: "pointer" }}
              >
                <div className="card single-promo-card single-promo-hover">
                  <div className="card-body">
                    <div className="pb-2">
                      <img
                        src={format.src}
                        style={{
                          backgroundColor: "#9629e6",
                          borderRadius: "100%",
                          border: "2px solid #FFF",
                          width: 75,
                        }}
                      />
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>{format.title}</h5>
                      <p className="text-muted mb-0">{format.des}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
