import React from "react";

export default function WinningObjective() {
  return (
    <>
      <section className="about-us ptb-100 gray-light-bg">
        <div style={{ height: 50 }} className="d-block d-lg-none"></div>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5 mt-5">
              <h2>About Qunions</h2>
              <p className="mb-2 lh-190">
                A mobile application which facilitates opinion trading and
                beyond. It’s a Questions and Opinions platform, where there are
                only opinions to questions – no factual single correct answer!
                Be the most popular or most unique in your opinion to win. There
                are 3 different Qunions formats:
              </p>
              <ul className="mt-3">
                <li className="mb-2">
                  &#x2022; <span className="text-bold">Trades</span> – Earn
                  profits from your strategic or honest opinions.
                </li>
                <li className="mb-2">
                  &#x2022; <span className="text-bold">Events</span> – Engage
                  with the larger audience to win a Mega prize.
                </li>
                <li>
                  &#x2022; <span className="text-bold">Game</span> – Play with
                  friends, family and beyond on any occasion.
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <div className="about-content-right">
                <img
                  src="assets/img/trophy.png"
                  width="500"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
