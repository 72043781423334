import moment from "moment";

export const formExpireTime = (timestamp) => {
  let duration = moment.duration(moment.unix(timestamp).diff(moment()));
  let minutes = duration.minutes();
  let hours = duration.hours();
  minutes = minutes <= 0 ? 1 : minutes;
  hours = hours <= 0 ? 0 : hours;
  let output = `${hours}h ${minutes}min`;
  let days = duration.days();
  if (days > 0) output = `${days}d ` + output;
  return output;
};
const formatNumber = (input) => {
  if (input <= 10) {
    return input;
  } else if (input <= 100) {
    let tens = Math.floor(input / 10) * 10;
    if (input - tens === 0) {
      return `${tens}+`;
    } else {
      return `${tens}+`;
    }
  } else {
    let hundreds = Math.floor(input / 100) * 100;
    if (input - hundreds === 0) {
      return `${hundreds}+`;
    } else {
      return `${hundreds}+`;
    }
  }
};
export const formEntries = (entries) => {
  return formatNumber(entries);
};
