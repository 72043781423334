import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  query,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore/lite";

import { app } from "./constants";
const firebaseApp = initializeApp(app.firebaseConfig);
const db = getFirestore(firebaseApp);

export const addDocToCollection = async (collection_name, doc) => {
  const collectionRef = collection(db, collection_name);
  return await addDoc(collectionRef, doc);
};

export const updateDocFromCollection = async (collection_name, id, _doc) => {
  try {
    return await updateDoc(doc(db, collection_name, id), {
      ..._doc,
    });
  } catch {}
};

export const getCollectionData = async (
  collection_name,
  where = null,
  order = null,
  overRideOrder = false
) => {
  const c = collection(db, collection_name);
  // Construct Query
  let q =
    where && where.length > 0
      ? query(c, ...where)
      : order
      ? query(c, order)
      : query(c);
  if (overRideOrder) q = query(q, order);
  // Fetch Data
  const querySnapshot = await getDocs(q);
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push({ ...doc.data(), id: doc.id });
  });
  return documents;
};

export const getTrades = async () => {
  let defaultTrades = [
    {
      trade_style: "SPLIT",
      question: "Which IPL 2023 team needs the most boost, talent wise?",
      trade_category: "cricket",
      bot_responses: [
        { amount: 94, number_of_responses: 94, option: "A" },
        { amount: 95, number_of_responses: 95, option: "B" },
        { amount: 95, number_of_responses: 95, option: "C" },
        { amount: 94, number_of_responses: 94, option: "D" },
      ],
      min_amount: 1,
      title: "Scope of Improvement",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1688330640, nanoseconds: 0 },
      options: [
        {
          text: "Delhi Capitals",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2F77tMyXx7OacSzAs6WOM4%2Fmedia_400x400.jpg?alt=media&token=71c97789-9421-4ac0-8570-f1a759b913bf",
            title:
              "media%2F77tMyXx7OacSzAs6WOM4%2Fmedia_400x400.jpg?alt=media&token=71c97789-9421-4ac0-8570-f1a759b913bf",
          },
        },
        {
          text: "Sunrisers Hyderabad",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FfQSPAbDDDdehZQIa71Kj%2Fmedia_400x400.jpg?alt=media&token=82095e44-040c-4766-a4cd-e7d3058505f1",
            title:
              "media%2FfQSPAbDDDdehZQIa71Kj%2Fmedia_400x400.jpg?alt=media&token=82095e44-040c-4766-a4cd-e7d3058505f1",
          },
        },
        {
          text: "Royal Challengers Banglores",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2F03UHklBlGePk2HbN1oTQ%2Fmedia_400x400.jpg?alt=media&token=765fe049-d92a-4434-83bb-53cf7d33858b",
            title:
              "media%2F03UHklBlGePk2HbN1oTQ%2Fmedia_400x400.jpg?alt=media&token=765fe049-d92a-4434-83bb-53cf7d33858b",
          },
        },
        {
          text: "Mumbai Indians",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2Fs87ZqSejqtL4IXiMvo29%2Fmedia_400x400.jpg?alt=media&token=00651028-47c8-495d-ab8d-300e7dad6112",
            title:
              "media%2Fs87ZqSejqtL4IXiMvo29%2Fmedia_400x400.jpg?alt=media&token=00651028-47c8-495d-ab8d-300e7dad6112",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "04m6mTchVopnekD2ohZV",
      updated_at: { seconds: 1685632483, nanoseconds: 266000000 },
      created_at: { seconds: 1685632483, nanoseconds: 266000000 },
      new_notification_id: "bjNF18XGkfVrVcOigSnw",
      status: "ACTIVE",
      majority_option: ["D", 25.45, 182, 5, 94],
      entries: 389,
      minority_option: ["B", 24.68, 145, 1, 95],
      majority_odds: 148,
      all_options_price: [
        {
          total_return: 269.33056218040235,
          total_amount: 182,
          total_real_users: 5,
          total_bots: 94,
          distribution: 25.45,
          type: "majority",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 147,
          total_real_users: 3,
          total_bots: 94,
          distribution: 24.94,
          type: "loss",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 137,
          total_real_users: 2,
          total_bots: 95,
          distribution: 24.94,
          type: "loss",
          option: "C",
        },
        {
          total_return: 311.11943781959764,
          total_amount: 145,
          total_real_users: 1,
          total_bots: 95,
          distribution: 24.68,
          type: "minority",
          option: "B",
        },
      ],
      minority_odds: 215,
    },
    {
      trade_style: "SPLIT",
      question:
        "What sport would be the funniest to add a mandatory amount of alcohol to?",
      trade_category: "laughter",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Playing with alcohol",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686603420, nanoseconds: 0 },
      options: [
        {
          text: "Gymnastics",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2Fwh6u1WFZMhHXItAHS9zC%2Fmedia.gif?alt=media&token=7bc607b8-7e33-4493-ae97-c239f889e5e8",
            title:
              "media%2Fwh6u1WFZMhHXItAHS9zC%2Fmedia.gif?alt=media&token=7bc607b8-7e33-4493-ae97-c239f889e5e8",
          },
        },
        {
          text: "Sumo Wrestling",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2F65ak1WlutjnNS4qbEZ0F%2Fmedia.gif?alt=media&token=10ccc71e-a639-4e6b-aeaa-81adf9d2af7e",
            title:
              "media%2F65ak1WlutjnNS4qbEZ0F%2Fmedia.gif?alt=media&token=10ccc71e-a639-4e6b-aeaa-81adf9d2af7e",
          },
        },
        {
          text: "Archery",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FXBGyEnugzUt5dSdGktx5%2Fmedia.gif?alt=media&token=a797767a-6459-4520-8084-36a21aeedea3",
            title:
              "media%2FXBGyEnugzUt5dSdGktx5%2Fmedia.gif?alt=media&token=a797767a-6459-4520-8084-36a21aeedea3",
          },
        },
        {
          text: "Synchronized swimming",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2Fw7TNcm3OwjMx9XdSuh1i%2Fmedia.gif?alt=media&token=b85e89b9-6ad4-4ebe-b727-ba4fd517b3b5",
            title:
              "media%2Fw7TNcm3OwjMx9XdSuh1i%2Fmedia.gif?alt=media&token=b85e89b9-6ad4-4ebe-b727-ba4fd517b3b5",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "4Fy2ax1kgo1o1z3QSVbd",
      created_at: { seconds: 1686083775, nanoseconds: 34000000 },
      new_notification_id: "4tzUd4WIyc9p2dSsblr8",
      updated_at: { seconds: 1686087635, nanoseconds: 363000000 },
      status: "ACTIVE",
      majority_option: ["A", 25.18, 171, 0, 171],
      entries: 679,
      minority_option: ["C", 24.74, 168, 0, 168],
      majority_odds: 234,
      all_options_price: [
        {
          total_return: 399.72625617194296,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.18,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 199,
          total_real_users: 1,
          total_bots: 169,
          distribution: 25.04,
          type: "loss",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 25.04,
          type: "loss",
          option: "B",
        },
        {
          total_return: 272.87374382805706,
          total_amount: 168,
          total_real_users: 0,
          total_bots: 168,
          distribution: 24.74,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 162,
    },
    {
      trade_style: "NOSPLIT",
      question:
        "Which IPL 2023 team should look out for talent most aggressively? ",
      trade_category: "cricket",
      bot_responses: [
        { amount: 95, number_of_responses: 95, option: "A" },
        { amount: 94, number_of_responses: 94, option: "B" },
        { amount: 94, number_of_responses: 94, option: "C" },
        { amount: 95, number_of_responses: 95, option: "D" },
      ],
      min_amount: 1,
      title: "Scope of Improvement",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1688589840, nanoseconds: 0 },
      question_image: {
        src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FmaSqYZcKMMCMcpK2iuRK%2Fmedia_400x400.jpg?alt=media&token=107150e2-ebec-45e6-93c1-0d44458683fe",
        title:
          "media%2FmaSqYZcKMMCMcpK2iuRK%2Fmedia_400x400.jpg?alt=media&token=107150e2-ebec-45e6-93c1-0d44458683fe",
      },
      options: [
        { text: "Delhi Capitals" },
        { text: "Sunrisers Hyderabad" },
        { text: "Royal Challengers Banglores" },
        { text: "Mumbai Indians" },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "67rBb2fMZUvSqPexkMJm",
      updated_at: { seconds: 1685632483, nanoseconds: 323000000 },
      created_at: { seconds: 1685632483, nanoseconds: 323000000 },
      new_notification_id: "zwRycvFTEJnygiC5moNO",
      status: "ACTIVE",
      majority_option: ["C", 25.77, 227, 6, 94],
      entries: 388,
      minority_option: ["B", 24.48, 102, 1, 94],
      majority_odds: 124,
      all_options_price: [
        {
          total_return: 280.7331399854399,
          total_amount: 227,
          total_real_users: 6,
          total_bots: 94,
          distribution: 25.77,
          type: "majority",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 136,
          total_real_users: 2,
          total_bots: 95,
          distribution: 25,
          type: "loss",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 97,
          total_real_users: 1,
          total_bots: 95,
          distribution: 24.74,
          type: "loss",
          option: "D",
        },
        {
          total_return: 253.1668600145601,
          total_amount: 102,
          total_real_users: 1,
          total_bots: 94,
          distribution: 24.48,
          type: "minority",
          option: "B",
        },
      ],
      minority_odds: 248,
    },
    {
      trade_style: "SPLIT",
      question: "Which look suits best on PM Modi's personality",
      bot_responses: [],
      trade_category: "politics",
      min_amount: 1,
      title: "PM Modi, in the making",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686429840, nanoseconds: 0 },
      options: [
        {
          text: "Clean shave",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2Fuf3j1PUo7auZpljoPAr8%2Fmedia_400x400.jpg?alt=media&token=60ef05c6-5fe9-4498-b742-738f574d95f9",
            title:
              "media%2Fuf3j1PUo7auZpljoPAr8%2Fmedia_400x400.jpg?alt=media&token=60ef05c6-5fe9-4498-b742-738f574d95f9",
          },
        },
        {
          text: "Dyed hair",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FeivTeaCyZFwi6aQVpH8K%2Fmedia_400x400.jpg?alt=media&token=0fd7d8f9-ec67-4d75-84b9-6d9a100fb877",
            title:
              "media%2FeivTeaCyZFwi6aQVpH8K%2Fmedia_400x400.jpg?alt=media&token=0fd7d8f9-ec67-4d75-84b9-6d9a100fb877",
          },
        },
        {
          text: "Regular beard",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FLYON6KzqrdfvpxsvsnFZ%2Fmedia_400x400.jpg?alt=media&token=61613610-438a-42b3-9cc7-4ba17f7ff0f6",
            title:
              "media%2FLYON6KzqrdfvpxsvsnFZ%2Fmedia_400x400.jpg?alt=media&token=61613610-438a-42b3-9cc7-4ba17f7ff0f6",
          },
        },
        {
          text: "Extra large beard",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2F3g04jYMP3dym8J4Otgsw%2Fmedia_400x400.jpg?alt=media&token=66f6ddbb-3808-40cc-89ec-130533dce4e4",
            title:
              "media%2F3g04jYMP3dym8J4Otgsw%2Fmedia_400x400.jpg?alt=media&token=66f6ddbb-3808-40cc-89ec-130533dce4e4",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "6herwJAZEliFSr73gaAw",
      updated_at: { seconds: 1685849940, nanoseconds: 154000000 },
      created_at: { seconds: 1685849940, nanoseconds: 154000000 },
      new_notification_id: "RGCuWIcrHS0acM7EQ2H9",
      status: "ACTIVE",
      majority_option: ["A", 33.33, 12, 1, 4],
      entries: 15,
      minority_option: ["C", 20, 3, 1, 2],
      majority_odds: 611,
      all_options_price: [
        {
          total_return: 73.31342553017333,
          total_amount: 12,
          total_real_users: 1,
          total_bots: 4,
          distribution: 33.33,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 53,
          total_real_users: 1,
          total_bots: 3,
          distribution: 26.67,
          type: "loss",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 51,
          total_real_users: 2,
          total_bots: 1,
          distribution: 20,
          type: "loss",
          option: "D",
        },
        {
          total_return: 39.736574469826664,
          total_amount: 3,
          total_real_users: 1,
          total_bots: 2,
          distribution: 20,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 1325,
    },
    {
      trade_style: "STANDARD",
      question:
        "Your boss got a new AI device that can know your prominent thoughts/habits. What you will be worried about more?",
      bot_responses: [],
      trade_category: "office",
      min_amount: 1,
      title: "AI gadget of the millenia",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686371520, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: "",
          text: "That, how much you love them, duh!",
          media: "",
        },
        {
          thumbnail: "",
          text: "That, never have you spoken the truth for taking timeoff",
          media: "",
        },
        {
          thumbnail: "",
          text: "That, the only thing you like about them is their spouse",
          media: "",
        },
        {
          thumbnail: "",
          text: "That, how you give your 100% at work - 15% Mon, 25% Tue, 40% Wed, 15% Thu, 5% Fri",
          media: "",
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "H8BTfqZHm0YpzquZsUDp",
      updated_at: { seconds: 1685853135, nanoseconds: 768000000 },
      created_at: { seconds: 1685853135, nanoseconds: 768000000 },
      new_notification_id: "bwuSLpVOiHbqkwDk8cGq",
      status: "ACTIVE",
      majority_option: ["A", 40, 13, 2, 4],
      entries: 15,
      minority_option: ["B", 20, 3, 0, 3],
      majority_odds: 408,
      all_options_price: [
        {
          total_return: 53,
          total_amount: 13,
          total_real_users: 2,
          total_bots: 4,
          distribution: 40,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 52,
          total_real_users: 2,
          total_bots: 1,
          distribution: 20,
          type: "loss",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 12,
          total_real_users: 1,
          total_bots: 2,
          distribution: 20,
          type: "loss",
          option: "C",
        },
        {
          total_return: 23,
          total_amount: 3,
          total_real_users: 0,
          total_bots: 3,
          distribution: 20,
          type: "minority",
          option: "B",
        },
      ],
      minority_odds: 767,
    },
    {
      trade_style: "NOSPLIT",
      question:
        "Your first day in the office and someone hot and sexy of opposite sex (perhaps accidentally) bumped into you. What should you say?",
      trade_category: "office",
      bot_responses: [
        { amount: 150, number_of_responses: 150, option: "A" },
        { amount: 151, number_of_responses: 151, option: "B" },
        { amount: 152, number_of_responses: 152, option: "C" },
        { amount: 149, number_of_responses: 149, option: "D" },
      ],
      min_amount: 1,
      title: "Falling for you",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686397080, nanoseconds: 0 },
      question_image: {
        src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FIB3MRRvImivmbr0oedhK%2Fquestion_image.jpg?alt=media&token=173620e8-6fb7-4ae9-b87c-812d122fdd0c",
        title: "Collision_office.jpg",
      },
      options: [
        { thumbnail: "", text: "Wish it was hard", media: "" },
        {
          thumbnail: "",
          text: "To every action there is an equal and opposite reaction",
          media: "",
        },
        { thumbnail: "", text: "Great bang! Up for another one?", media: "" },
        {
          thumbnail: "",
          text: "Looking forward to not looking forward",
          media: "",
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "IB3MRRvImivmbr0oedhK",
      updated_at: { seconds: 1685853413, nanoseconds: 845000000 },
      created_at: { seconds: 1685853413, nanoseconds: 845000000 },
      new_notification_id: "6c8HHs4yZkWr3d6D7J90",
      status: "ACTIVE",
      majority_option: ["B", 25.25, 202, 2, 151],
      entries: 606,
      minority_option: ["A", 24.75, 150, 0, 150],
      majority_odds: 175,
      all_options_price: [
        {
          total_return: 352.86875000000003,
          total_amount: 202,
          total_real_users: 2,
          total_bots: 151,
          distribution: 25.25,
          type: "majority",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 154,
          total_real_users: 1,
          total_bots: 152,
          distribution: 25.25,
          type: "loss",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 179,
          total_real_users: 1,
          total_bots: 149,
          distribution: 24.75,
          type: "loss",
          option: "D",
        },
        {
          total_return: 297.88125,
          total_amount: 150,
          total_real_users: 0,
          total_bots: 150,
          distribution: 24.75,
          type: "minority",
          option: "A",
        },
      ],
      minority_odds: 199,
    },
    {
      trade_style: "NOSPLIT",
      question: "Who is the most technically sound Aussie batter? ",
      trade_category: "cricket",
      bot_responses: [
        { amount: 321, number_of_responses: 321, option: "A" },
        { amount: 324, number_of_responses: 324, option: "B" },
        { amount: 323, number_of_responses: 323, option: "C" },
        { amount: 322, number_of_responses: 322, option: "D" },
      ],
      min_amount: 1,
      title: "Technically sound Aussie batter",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686606660, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: "",
          text: "David Warner",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FLMWQxoEL8b1giCBVlqc4%2Foptions%2F0%2Fmedia_400x400.jpg?alt=media&token=7218008e-d08f-4b02-ba3d-16862887a355",
            title: "David_warner.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Usman Khawaja",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FLMWQxoEL8b1giCBVlqc4%2Foptions%2F1%2Fmedia_400x400.jpg?alt=media&token=0908e10b-327d-41dd-b127-7662c57b6001",
            title: "Usman_Khwaja.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Marnus Labuschagne",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FLMWQxoEL8b1giCBVlqc4%2Foptions%2F2%2Fmedia_400x400.jpg?alt=media&token=63c0c669-1303-44ec-b293-7fffdc4e2289",
            title: "marnus_labuschagne.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Steve Smith",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FLMWQxoEL8b1giCBVlqc4%2Foptions%2F3%2Fmedia_400x400.jpg?alt=media&token=145c3be4-eec1-4f23-85b0-c4e5b9a346bb",
            title: "Steve Smith.jpg",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "LMWQxoEL8b1giCBVlqc4",
      created_at: { seconds: 1686088836, nanoseconds: 361000000 },
      new_notification_id: "lYP3msze7yhJfFmvnDte",
      updated_at: { seconds: 1686089010, nanoseconds: 162000000 },
      status: "ACTIVE",
      majority_option: ["B", 25.1, 324, 0, 324],
      entries: 1291,
      minority_option: ["D", 24.94, 322, 0, 322],
      majority_odds: 191,
      all_options_price: [
        {
          total_return: 619.9181654676195,
          total_amount: 324,
          total_real_users: 0,
          total_bots: 324,
          distribution: 25.1,
          type: "majority",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 323,
          total_real_users: 0,
          total_bots: 323,
          distribution: 25.02,
          type: "loss",
          option: "C",
        },
        {
          total_return: 616.0318345323807,
          total_amount: 322,
          total_real_users: 0,
          total_bots: 322,
          distribution: 24.94,
          type: "minority",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 332,
          total_real_users: 1,
          total_bots: 321,
          distribution: 24.94,
          type: "loss",
          option: "A",
        },
      ],
      minority_odds: 191,
    },
    {
      trade_style: "SPLIT",
      question: "Which is the biggest miss/failure of Modi government so far?",
      trade_category: "politics",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Modi Ji - a human afterall",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686429840, nanoseconds: 0 },
      options: [
        {
          text: "Demonetization",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2F4qkAp5rS9l6B1V2NWpFs%2Fmedia_400x400.jpg?alt=media&token=24d410a2-98ed-42c1-bad8-8ef5f4cd66ac",
            title:
              "media%2F4qkAp5rS9l6B1V2NWpFs%2Fmedia_400x400.jpg?alt=media&token=24d410a2-98ed-42c1-bad8-8ef5f4cd66ac",
          },
        },
        {
          text: "Farm laws reform bills",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FZyVbmrFASQDF3LQ9p8UL%2Fmedia_400x400.jpg?alt=media&token=920f2e30-bd35-46e8-9eba-160ae1d7a0d2",
            title:
              "media%2FZyVbmrFASQDF3LQ9p8UL%2Fmedia_400x400.jpg?alt=media&token=920f2e30-bd35-46e8-9eba-160ae1d7a0d2",
          },
        },
        {
          text: "Sensitive Kashmir issue",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2F2MRHsNwZiTiDsDV1Gk3p%2Fmedia.gif?alt=media&token=56184dc8-623a-4b80-aa7b-2b459b181752",
            title:
              "media%2F2MRHsNwZiTiDsDV1Gk3p%2Fmedia.gif?alt=media&token=56184dc8-623a-4b80-aa7b-2b459b181752",
          },
        },
        {
          text: "Jobs creation",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FUz1QVFqEFiVTeGseEIYv%2Fmedia_400x400.jpg?alt=media&token=42fb9a23-9eeb-41af-82c7-bd2bf7ac9a43",
            title:
              "media%2FUz1QVFqEFiVTeGseEIYv%2Fmedia_400x400.jpg?alt=media&token=42fb9a23-9eeb-41af-82c7-bd2bf7ac9a43",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "LoPRNhCbFsldOnK50YEl",
      updated_at: { seconds: 1685849940, nanoseconds: 94000000 },
      created_at: { seconds: 1685849940, nanoseconds: 94000000 },
      new_notification_id: "YIHmieQsS76YmRPHLprd",
      status: "ACTIVE",
      majority_option: ["D", 25.18, 222, 3, 169],
      entries: 683,
      minority_option: ["C", 24.74, 218, 1, 168],
      majority_odds: 202,
      all_options_price: [
        {
          total_return: 448.5665208393841,
          total_amount: 222,
          total_real_users: 3,
          total_bots: 169,
          distribution: 25.18,
          type: "majority",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 200,
          total_real_users: 1,
          total_bots: 170,
          distribution: 25.04,
          type: "loss",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.04,
          type: "loss",
          option: "A",
        },
        {
          total_return: 321.8834791606158,
          total_amount: 218,
          total_real_users: 1,
          total_bots: 168,
          distribution: 24.74,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 148,
    },
    {
      trade_style: "NOSPLIT",
      question: "Which batter quality is most important in test cricket?",
      trade_category: "cricket",
      bot_responses: [
        { amount: 178, number_of_responses: 178, option: "A" },
        { amount: 179, number_of_responses: 179, option: "B" },
        { amount: 180, number_of_responses: 180, option: "C" },
        { amount: 181, number_of_responses: 181, option: "D" },
      ],
      min_amount: 1,
      title: "A great batter",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686522660, nanoseconds: 0 },
      question_image: {
        src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FMKFYNvqys3pLW1XKEf2A%2Fquestion_image.jpg?alt=media&token=a5bb7123-5e82-44ef-a9d7-38fde56e6a93",
        title: "Cricket_skill.jpg",
      },
      options: [
        { thumbnail: "", text: "Positive Intent", media: "" },
        { thumbnail: "", text: "Focus and Concentration", media: "" },
        { thumbnail: "", text: "technically sound", media: "" },
        { thumbnail: "", text: "Perseverance", media: "" },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "MKFYNvqys3pLW1XKEf2A",
      created_at: { seconds: 1686090845, nanoseconds: 338000000 },
      new_notification_id: "NiLfPdjuCbmVbpyLDQpe",
      updated_at: { seconds: 1686090901, nanoseconds: 973000000 },
      status: "ACTIVE",
      majority_option: ["D", 25.17, 181, 0, 181],
      entries: 719,
      minority_option: ["B", 24.9, 179, 0, 179],
      majority_odds: 188,
      all_options_price: [
        {
          total_return: 339.7124162116584,
          total_amount: 181,
          total_real_users: 0,
          total_bots: 181,
          distribution: 25.17,
          type: "majority",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 180,
          total_real_users: 0,
          total_bots: 180,
          distribution: 25.03,
          type: "loss",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 184,
          total_real_users: 1,
          total_bots: 178,
          distribution: 24.9,
          type: "loss",
          option: "A",
        },
        {
          total_return: 348.0875837883416,
          total_amount: 179,
          total_real_users: 0,
          total_bots: 179,
          distribution: 24.9,
          type: "minority",
          option: "B",
        },
      ],
      minority_odds: 194,
    },
    {
      trade_style: "SPLIT",
      question: "Which is the most oddly satisfying smell?",
      trade_category: "this_or_that",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Oddly satisfying smell...",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686430620, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: "",
          text: "Gasoline",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FOWYxFJNn4Mp1NugN6SC1%2Foptions%2F0%2Fmedia_400x400.jpg?alt=media&token=565240fc-0121-44b1-bfdf-b4bd3a858138",
            title: "Gasoline.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Permanent markers",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FOWYxFJNn4Mp1NugN6SC1%2Foptions%2F1%2Fmedia_400x400.jpg?alt=media&token=5b702037-37ac-424c-8c6a-03c6f34a349d",
            title: "Perm_marker.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Newly printed books",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FOWYxFJNn4Mp1NugN6SC1%2Foptions%2F2%2Fmedia_400x400.jpg?alt=media&token=ccbf6bdb-3d88-45db-8939-a1c5eed06fba",
            title: "New_books.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Your own fart",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FOWYxFJNn4Mp1NugN6SC1%2Foptions%2F3%2Fmedia_400x400.jpg?alt=media&token=8999c521-4b39-4511-ad85-b85e854b001c",
            title: "Own_fart.jpg",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "OWYxFJNn4Mp1NugN6SC1",
      updated_at: { seconds: 1685853606, nanoseconds: 798000000 },
      created_at: { seconds: 1685853606, nanoseconds: 798000000 },
      new_notification_id: "oAdhS4J69mvFWriLELp4",
      status: "ACTIVE",
      minority_odds: 161,
      majority_option: ["D", 25.26, 205, 3, 169],
      entries: 681,
      minority_option: ["C", 24.67, 168, 0, 168],
      majority_odds: 199,
      all_options_price: [
        {
          total_return: 407.8355617228514,
          total_amount: 205,
          total_real_users: 3,
          total_bots: 169,
          distribution: 25.26,
          type: "majority",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.11,
          type: "loss",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 24.96,
          type: "loss",
          option: "B",
        },
        {
          total_return: 270.4644382771486,
          total_amount: 168,
          total_real_users: 0,
          total_bots: 168,
          distribution: 24.67,
          type: "minority",
          option: "C",
        },
      ],
    },
    {
      trade_style: "STANDARD",
      question: "What is the worst/most annoying catchphrase?",
      trade_category: "this_or_that",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Phrase that never should exist",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686603420, nanoseconds: 0 },
      options: [
        { text: "YOLO (You Only Live Once)" },
        { text: "Keep calm and…" },
        { text: "It is what it is." },
        { text: "I’m living my best life." },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "PEmzKcm5tst8lv32Yb8A",
      created_at: { seconds: 1686083775, nanoseconds: 237000000 },
      new_notification_id: "2RvcKJO6pxZfAl1dWj1t",
      majority_option: ["A", 25.22, 171, 0, 171],
      entries: 678,
      minority_option: ["C", 24.78, 168, 0, 168],
      majority_odds: 190,
      all_options_price: [
        {
          total_return: 324.89244,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.22,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 25.07,
          type: "loss",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 169,
          total_real_users: 0,
          total_bots: 169,
          distribution: 24.93,
          type: "loss",
          option: "D",
        },
        {
          total_return: 319.20756000000006,
          total_amount: 168,
          total_real_users: 0,
          total_bots: 168,
          distribution: 24.78,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 190,
      updated_at: { seconds: 1686087634, nanoseconds: 592000000 },
      status: "ACTIVE",
    },
    {
      trade_style: "NOSPLIT",
      question: "What superhero/villain would make the best therapist?",
      trade_category: "movie",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Best Therapist",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686603420, nanoseconds: 0 },
      options: [
        {
          text: "Harley Quinn",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FVgsMdKmRlPRTRuyTwIwS%2Fmedia_400x400.jpg?alt=media&token=ad567498-3079-4399-a267-2131f283d62e",
            title:
              "media%2FVgsMdKmRlPRTRuyTwIwS%2Fmedia_400x400.jpg?alt=media&token=ad567498-3079-4399-a267-2131f283d62e",
          },
        },
        {
          text: "Joker",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FFebeQzi4lzQrkYC3pxbw%2Fmedia_400x400.jpg?alt=media&token=96ca5b6b-8232-4adc-a625-025434c500b6",
            title:
              "media%2FFebeQzi4lzQrkYC3pxbw%2Fmedia_400x400.jpg?alt=media&token=96ca5b6b-8232-4adc-a625-025434c500b6",
          },
        },
        {
          text: "Professor X",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FW2VZIdxGLkwo3HmpphPf%2Fmedia_400x400.jpg?alt=media&token=3f0d079e-865d-4bb5-a2ac-e8d91d985d3d",
            title:
              "media%2FW2VZIdxGLkwo3HmpphPf%2Fmedia_400x400.jpg?alt=media&token=3f0d079e-865d-4bb5-a2ac-e8d91d985d3d",
          },
        },
        {
          text: "Doctor Strange",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2Fj7diRvpZ94FKwaLuWQaA%2Fmedia_400x400.jpg?alt=media&token=c702b9bd-c3fc-4850-9079-dca77b421e0a",
            title:
              "media%2Fj7diRvpZ94FKwaLuWQaA%2Fmedia_400x400.jpg?alt=media&token=c702b9bd-c3fc-4850-9079-dca77b421e0a",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "PSIM6uTdxhm5dISbl0R3",
      created_at: { seconds: 1686083775, nanoseconds: 289000000 },
      new_notification_id: "towfUjjuikxRRxtootby",
      majority_option: ["A", 25.22, 171, 0, 171],
      entries: 678,
      minority_option: ["C", 24.78, 168, 0, 168],
      majority_odds: 190,
      all_options_price: [
        {
          total_return: 324.89244,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.22,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 25.07,
          type: "loss",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 169,
          total_real_users: 0,
          total_bots: 169,
          distribution: 24.93,
          type: "loss",
          option: "D",
        },
        {
          total_return: 319.20756000000006,
          total_amount: 168,
          total_real_users: 0,
          total_bots: 168,
          distribution: 24.78,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 190,
      updated_at: { seconds: 1686087633, nanoseconds: 973000000 },
      status: "ACTIVE",
    },
    {
      trade_style: "SPLIT",
      question: "Which Indian bowler holds the key in WTC final",
      trade_category: "cricket",
      bot_responses: [
        { amount: 467, number_of_responses: 467, option: "A" },
        { amount: 466, number_of_responses: 466, option: "B" },
        { amount: 465, number_of_responses: 465, option: "C" },
        { amount: 464, number_of_responses: 464, option: "D" },
      ],
      min_amount: 1,
      title: "Our Key bowler to in WTC ",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686345360, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: "",
          text: "Mohammed Shami",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FREYOaS1eTOT33CPhSLoj%2Foptions%2F0%2Fmedia.gif?alt=media&token=de2a4cc8-5694-4cb6-bcd5-b5eeb067db03",
            title: "mohammed-shami-gif.gif",
          },
        },
        {
          thumbnail: "",
          text: "Mohammad Siraj",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FREYOaS1eTOT33CPhSLoj%2Foptions%2F1%2Fmedia.gif?alt=media&token=0bafe5d8-b44f-40a5-9431-989919653009",
            title: "mohammed--siraj-gif.gif",
          },
        },
        {
          thumbnail: "",
          text: "Ravindra Jadeja",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FREYOaS1eTOT33CPhSLoj%2Foptions%2F2%2Fmedia.gif?alt=media&token=73a1f6ab-d10c-4345-ae9c-5a167afab7cb",
            title: "jaddu-2.0-loading-jadeja.gif",
          },
        },
        {
          thumbnail: "",
          text: "Any Other",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FREYOaS1eTOT33CPhSLoj%2Foptions%2F3%2Fmedia.gif?alt=media&token=a588f133-220e-415f-8e7b-3abde94d4c53",
            title: "Any_other.gif",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "REYOaS1eTOT33CPhSLoj",
      created_at: { seconds: 1686086393, nanoseconds: 898000000 },
      new_notification_id: "PKGbJGqa7hb3oF2Qzulp",
      updated_at: { seconds: 1686087612, nanoseconds: 816000000 },
      status: "ACTIVE",
      majority_option: ["A", 25.13, 550, 2, 467],
      entries: 1866,
      minority_option: ["D", 24.87, 464, 0, 464],
      majority_odds: 180,
      all_options_price: [
        {
          total_return: 987.6640800000001,
          total_amount: 550,
          total_real_users: 2,
          total_bots: 467,
          distribution: 25.13,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 491,
          total_real_users: 1,
          total_bots: 466,
          distribution: 25.03,
          type: "loss",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 479,
          total_real_users: 1,
          total_bots: 465,
          distribution: 24.97,
          type: "loss",
          option: "C",
        },
        {
          total_return: 897.1359199999999,
          total_amount: 464,
          total_real_users: 0,
          total_bots: 464,
          distribution: 24.87,
          type: "minority",
          option: "D",
        },
      ],
      minority_odds: 193,
    },
    {
      trade_style: "NOSPLIT",
      question:
        '"Agar Dhinchak pooja k paas scooter nahi hota to wo kisko ""Dilo ka shooter"" banati"',
      trade_category: "laughter",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Dhinchak Pooja",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686429840, nanoseconds: 0 },
      options: [
        {
          text: "Looter - Boyfriend or partner",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FUduG5KB3Lhn38Gz7m0B1%2Fmedia_400x400.jpg?alt=media&token=edcd2647-dee6-4d2d-84d4-7763ff0ff5c1",
            title:
              "media%2FUduG5KB3Lhn38Gz7m0B1%2Fmedia_400x400.jpg?alt=media&token=edcd2647-dee6-4d2d-84d4-7763ff0ff5c1",
          },
        },
        {
          text: "Computer - Literally it could be for what it stores!",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FBViYRoWCE6ktbNuTweO6%2Fmedia_400x400.jpg?alt=media&token=ed67bc0f-1c16-4c90-8e11-0f0350e45919",
            title:
              "media%2FBViYRoWCE6ktbNuTweO6%2Fmedia_400x400.jpg?alt=media&token=ed67bc0f-1c16-4c90-8e11-0f0350e45919",
          },
        },
        {
          text: "Tutor - Nobody does it better",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FlDOyjGrQqLI9B9PG9evK%2Fmedia_400x400.jpg?alt=media&token=45ed5c8b-a118-4346-8d7b-449a687aab59",
            title:
              "media%2FlDOyjGrQqLI9B9PG9evK%2Fmedia_400x400.jpg?alt=media&token=45ed5c8b-a118-4346-8d7b-449a687aab59",
          },
        },
        {
          text: "Chutad - aka buttock or gaand",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FsJH6Iuf8NX9UE3IcafBj%2Fmedia_400x400.jpg?alt=media&token=a9046d9a-d38f-4453-9b0e-a4aad73c1ef6",
            title:
              "media%2FsJH6Iuf8NX9UE3IcafBj%2Fmedia_400x400.jpg?alt=media&token=a9046d9a-d38f-4453-9b0e-a4aad73c1ef6",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "SFh9lhY4SdMJx6t4LNMG",
      updated_at: { seconds: 1685849940, nanoseconds: 38000000 },
      created_at: { seconds: 1685849940, nanoseconds: 38000000 },
      new_notification_id: "wrfRItwKZKieF4IXpcFH",
      status: "ACTIVE",
      majority_option: ["D", 25.07, 181, 2, 169],
      entries: 682,
      minority_option: ["C", 24.78, 170, 1, 168],
      majority_odds: 198,
      all_options_price: [
        {
          total_return: 0,
          total_amount: 220,
          total_real_users: 1,
          total_bots: 170,
          distribution: 25.07,
          type: "loss",
          option: "B",
        },
        {
          total_return: 358.9793981945837,
          total_amount: 181,
          total_real_users: 2,
          total_bots: 169,
          distribution: 25.07,
          type: "majority",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.07,
          type: "loss",
          option: "A",
        },
        {
          total_return: 345.92060180541625,
          total_amount: 170,
          total_real_users: 1,
          total_bots: 168,
          distribution: 24.78,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 203,
    },
    {
      trade_style: "NOSPLIT",
      question:
        "What is the most common opinion among retirees about reality TV shows?",
      trade_category: "laughter",
      bot_responses: [
        { amount: 170, number_of_responses: 170, option: "A" },
        { amount: 169, number_of_responses: 169, option: "B" },
        { amount: 171, number_of_responses: 171, option: "C" },
        { amount: 168, number_of_responses: 168, option: "D" },
      ],
      min_amount: 1,
      title: "Reality TVs and Retirees",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686603420, nanoseconds: 0 },
      question_image: {
        src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FXlLxKqjCURznwOtQAAYI%2Fmedia_400x400.jpg?alt=media&token=d69d7e48-967a-47a6-900e-1bafbd7751b8",
        title:
          "media%2FXlLxKqjCURznwOtQAAYI%2Fmedia_400x400.jpg?alt=media&token=d69d7e48-967a-47a6-900e-1bafbd7751b8",
      },
      options: [
        {
          text: "The perfect blend of manufactured drama and questionable talent.",
        },
        {
          text: "Where real people strive to be famous for doing absolutely nothing.",
        },
        {
          text: "Reality TV is like a car crash you can't look away from—except it's a car crash of human dignity.",
        },
        {
          text: "Love reality TV because it makes me feel incredibly productive in comparison to the participants.",
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "TSuFnrxRPDEmmYjzLaKx",
      created_at: { seconds: 1686083774, nanoseconds: 967000000 },
      new_notification_id: "efCK1y9E9VAe9RcLbi5G",
      majority_option: ["C", 25.22, 171, 0, 171],
      entries: 678,
      minority_option: ["D", 24.78, 168, 0, 168],
      majority_odds: 190,
      all_options_price: [
        {
          total_return: 324.89244,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.22,
          type: "majority",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 25.07,
          type: "loss",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 169,
          total_real_users: 0,
          total_bots: 169,
          distribution: 24.93,
          type: "loss",
          option: "B",
        },
        {
          total_return: 319.20756000000006,
          total_amount: 168,
          total_real_users: 0,
          total_bots: 168,
          distribution: 24.78,
          type: "minority",
          option: "D",
        },
      ],
      minority_odds: 190,
      updated_at: { seconds: 1686087636, nanoseconds: 50000000 },
      status: "ACTIVE",
    },
    {
      trade_style: "SPLIT",
      question: 'Most "Solid as a Rock" Australian batsman?',
      trade_category: "cricket",
      bot_responses: [
        { amount: 321, number_of_responses: 321, option: "A" },
        { amount: 322, number_of_responses: 322, option: "B" },
        { amount: 323, number_of_responses: 323, option: "C" },
        { amount: 324, number_of_responses: 324, option: "D" },
      ],
      min_amount: 1,
      title: "Australian Wall",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686520260, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: "",
          text: "David Warner",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FWN3UEfmo7ZhfwhlItfKF%2Foptions%2F0%2Fmedia_400x400.jpg?alt=media&token=841c66d1-1b03-4c22-9855-f5838e0309bf",
            title: "David_warner.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Usman Khawaja",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FWN3UEfmo7ZhfwhlItfKF%2Foptions%2F1%2Fmedia_400x400.jpg?alt=media&token=403c8c54-ae34-42f7-8367-9e895d1baa42",
            title: "Usman_Khwaja.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Marnus Labuschagne",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FWN3UEfmo7ZhfwhlItfKF%2Foptions%2F2%2Fmedia_400x400.jpg?alt=media&token=a1c39e1e-996c-47dd-bbe1-745e8e92524f",
            title: "marnus_labuschagne.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Steve Smith",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FWN3UEfmo7ZhfwhlItfKF%2Foptions%2F3%2Fmedia_400x400.jpg?alt=media&token=7554ab1f-3f1d-4025-b7a1-c4e5c8f5459b",
            title: "Steve Smith.jpg",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "WN3UEfmo7ZhfwhlItfKF",
      created_at: { seconds: 1686088482, nanoseconds: 828000000 },
      new_notification_id: "5hJyKSmD5l5kw37RJBrv",
      updated_at: { seconds: 1686088671, nanoseconds: 19000000 },
      status: "ACTIVE",
      majority_option: ["D", 25.14, 374, 1, 324],
      entries: 1293,
      minority_option: ["B", 24.9, 322, 0, 322],
      majority_odds: 154,
      all_options_price: [
        {
          total_return: 577.7634907926371,
          total_amount: 374,
          total_real_users: 1,
          total_bots: 324,
          distribution: 25.14,
          type: "majority",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 353,
          total_real_users: 2,
          total_bots: 321,
          distribution: 24.98,
          type: "loss",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 323,
          total_real_users: 0,
          total_bots: 323,
          distribution: 24.98,
          type: "loss",
          option: "C",
        },
        {
          total_return: 725.6365092073628,
          total_amount: 322,
          total_real_users: 0,
          total_bots: 322,
          distribution: 24.9,
          type: "minority",
          option: "B",
        },
      ],
      minority_odds: 225,
    },
    {
      trade_style: "NOSPLIT",
      question:
        "A true 360 player! Which 6 hitting shot a budding teenager should learn first from SKY's playbook?  ",
      trade_category: "cricket",
      bot_responses: [
        { amount: 200, number_of_responses: 200, option: "A" },
        { amount: 202, number_of_responses: 202, option: "B" },
        { amount: 201, number_of_responses: 201, option: "C" },
        { amount: 199, number_of_responses: 199, option: "D" },
      ],
      min_amount: 1,
      title: "SKY special - always a treat!",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686371040, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fh6DeGKBDAHR7TCghGkBi%2Foptions%2F0%2Fthumbnail.jpg?alt=media&token=4acc138a-8c56-43ac-8cbc-2804c94fc670",
          },
          text: "Scoop over slip cordon and deep 3rd.",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fh6DeGKBDAHR7TCghGkBi%2Foptions%2F0%2Fmedia_400x400.jpg?alt=media&token=f204bcdf-1054-4c9c-9e8a-f9e70ebfcb2a",
          },
        },
        {
          thumbnail: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fh6DeGKBDAHR7TCghGkBi%2Foptions%2F1%2Fthumbnail.jpg?alt=media&token=cd03e586-def1-41be-83be-bfd05bc1ffa0",
          },
          text: "Whip off the hips behind square.",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fh6DeGKBDAHR7TCghGkBi%2Foptions%2F1%2Fmedia_400x400.jpg?alt=media&token=9b8f29c9-0836-4cc1-962b-4be9b5ed4400",
          },
        },
        {
          thumbnail: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fh6DeGKBDAHR7TCghGkBi%2Foptions%2F2%2Fthumbnail.jpg?alt=media&token=79a4e395-2a31-41ae-b8d8-d5ffb1c64270",
          },
          text: "Sweep 140KPH+ with open bat face over deep square leg",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fh6DeGKBDAHR7TCghGkBi%2Foptions%2F2%2Fmedia_400x400.jpg?alt=media&token=22c2d18d-c38d-4299-81e7-755f1cbd1da9",
          },
        },
        {
          thumbnail: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fh6DeGKBDAHR7TCghGkBi%2Foptions%2F3%2Fthumbnail.jpg?alt=media&token=492bcc0b-9e1d-4ed5-a961-3da0224dd9a2",
          },
          text: "Water like fluid dancing lofted straight drive over long off",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fh6DeGKBDAHR7TCghGkBi%2Foptions%2F3%2Fmedia_400x400.jpg?alt=media&token=bcfa6218-b69e-4dcb-8f91-54a4d630918b",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "emmvFK23ofWHXLTUcLKN",
      updated_at: { seconds: 1685852735, nanoseconds: 485000000 },
      created_at: { seconds: 1685852735, nanoseconds: 485000000 },
      new_notification_id: "j0EIgJi59hwaen3IFfQW",
      status: "ACTIVE",
      majority_option: ["B", 25.46, 358, 5, 202],
      entries: 813,
      minority_option: ["D", 24.6, 201, 1, 199],
      majority_odds: 151,
      all_options_price: [
        {
          total_return: 540.7977629792458,
          total_amount: 358,
          total_real_users: 5,
          total_bots: 202,
          distribution: 25.46,
          type: "majority",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 257,
          total_real_users: 3,
          total_bots: 201,
          distribution: 25.09,
          type: "loss",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 240,
          total_real_users: 2,
          total_bots: 200,
          distribution: 24.85,
          type: "loss",
          option: "A",
        },
        {
          total_return: 462.40223702075417,
          total_amount: 201,
          total_real_users: 1,
          total_bots: 199,
          distribution: 24.6,
          type: "minority",
          option: "D",
        },
      ],
      minority_odds: 230,
    },
    {
      trade_style: "NOSPLIT",
      question: "How do night owls feel about early mornings?",
      trade_category: "laughter",
      bot_responses: [
        { amount: 170, number_of_responses: 170, option: "A" },
        { amount: 168, number_of_responses: 168, option: "B" },
        { amount: 171, number_of_responses: 171, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Night Owls early mornings…",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686603420, nanoseconds: 0 },
      question_image: {
        src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FYeb5eSXD7OK1SjSNHbgk%2Fmedia_400x400.jpg?alt=media&token=b972b925-ad01-4acf-aabe-f1e893ddc0c5",
        title:
          "media%2FYeb5eSXD7OK1SjSNHbgk%2Fmedia_400x400.jpg?alt=media&token=b972b925-ad01-4acf-aabe-f1e893ddc0c5",
      },
      options: [
        {
          text: "Ah, early mornings, the time of day when even the birds question their life choices.",
        },
        {
          text: "Early mornings: the perfect opportunity to hit the snooze button repeatedly and dream of a more reasonable hour.",
        },
        {
          text: "Early mornings were invented to test the limits of human grumpiness.",
        },
        {
          text: "Early mornings are like a surprise visit from a distant relative - unwelcome and far too frequent.",
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "f4XrzG4DvrcytEhXWTyr",
      created_at: { seconds: 1686083774, nanoseconds: 912000000 },
      new_notification_id: "yAOba7hYDLcxLS28QZW7",
      majority_option: ["C", 25.22, 171, 0, 171],
      entries: 678,
      minority_option: ["B", 24.78, 168, 0, 168],
      majority_odds: 190,
      all_options_price: [
        {
          total_return: 324.89244,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.22,
          type: "majority",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 25.07,
          type: "loss",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 169,
          total_real_users: 0,
          total_bots: 169,
          distribution: 24.93,
          type: "loss",
          option: "D",
        },
        {
          total_return: 319.20756000000006,
          total_amount: 168,
          total_real_users: 0,
          total_bots: 168,
          distribution: 24.78,
          type: "minority",
          option: "B",
        },
      ],
      minority_odds: 190,
      updated_at: { seconds: 1686087636, nanoseconds: 563000000 },
      status: "ACTIVE",
    },
    {
      trade_style: "SPLIT",
      question: "A beer night with old buddies still worth it, if?",
      trade_category: "this_or_that",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Beer night with budies",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686516240, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: "",
          text: "Foot in poop",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FiYB1PibFLznmKdDDxGdX%2Foptions%2F0%2Fmedia_400x400.jpg?alt=media&token=8b586d94-9025-4ec0-82ee-9d99a154689a",
            title: "foot in poop.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Drenched in rain",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FiYB1PibFLznmKdDDxGdX%2Foptions%2F1%2Fmedia_400x400.jpg?alt=media&token=85e21b04-eac5-4376-8695-5c304409652d",
            title: "drenched_rain.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Droppings on head",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FiYB1PibFLznmKdDDxGdX%2Foptions%2F2%2Fmedia_400x400.jpg?alt=media&token=0bf06b85-1875-4ac5-aa62-dec71782da1e",
            title: "beatonhead.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Unexpected chase",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FiYB1PibFLznmKdDDxGdX%2Foptions%2F3%2Fmedia_400x400.jpg?alt=media&token=0c055619-c11c-4f49-a7be-54e67d0577ce",
            title: "dog_chased.jpg",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "iYB1PibFLznmKdDDxGdX",
      updated_at: { seconds: 1685853975, nanoseconds: 257000000 },
      created_at: { seconds: 1685853975, nanoseconds: 257000000 },
      new_notification_id: "wUr7BMh9MdczN1k6mxH9",
      status: "ACTIVE",
      majority_option: ["B", 25.11, 207, 1, 170],
      entries: 681,
      minority_option: ["C", 24.82, 170, 1, 168],
      majority_odds: 172,
      all_options_price: [
        {
          total_return: 0,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.11,
          type: "loss",
          option: "A",
        },
        {
          total_return: 355.17480935013367,
          total_amount: 207,
          total_real_users: 1,
          total_bots: 170,
          distribution: 25.11,
          type: "majority",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 1,
          total_bots: 169,
          distribution: 24.96,
          type: "loss",
          option: "D",
        },
        {
          total_return: 326.92519064986635,
          total_amount: 170,
          total_real_users: 1,
          total_bots: 168,
          distribution: 24.82,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 192,
    },
    {
      trade_style: "SPLIT",
      question: "A beer night with old buddies still worth it, if?",
      trade_category: "this or that",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Beer night with budies",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686429840, nanoseconds: 0 },
      options: [
        {
          text: "Foot in poop",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FwT5ec2KbxRfi0BQi7Ew8%2Fmedia_400x400.jpg?alt=media&token=8de8f305-f785-4503-a928-b66366878057",
            title:
              "media%2FwT5ec2KbxRfi0BQi7Ew8%2Fmedia_400x400.jpg?alt=media&token=8de8f305-f785-4503-a928-b66366878057",
          },
        },
        {
          text: "Drenched in rain",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FSbpXGvHfCowZBowYDbQP%2Fmedia_400x400.jpg?alt=media&token=4a86a18f-bea6-42a0-b1f8-7f02b8763041",
            title:
              "media%2FSbpXGvHfCowZBowYDbQP%2Fmedia_400x400.jpg?alt=media&token=4a86a18f-bea6-42a0-b1f8-7f02b8763041",
          },
        },
        {
          text: "Droppings on head",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2F5HNmaUPqQ5fBY21znssW%2Fmedia_400x400.jpg?alt=media&token=371b96d9-3195-47d1-9b9e-e1b1a209ca78",
            title:
              "media%2F5HNmaUPqQ5fBY21znssW%2Fmedia_400x400.jpg?alt=media&token=371b96d9-3195-47d1-9b9e-e1b1a209ca78",
          },
        },
        {
          text: "Unexpected chase",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FWiL876EcZRun3eQ9RlUA%2Fmedia_400x400.jpg?alt=media&token=401e3b8a-0f06-412c-b3fd-ed86a87dea60",
            title:
              "media%2FWiL876EcZRun3eQ9RlUA%2Fmedia_400x400.jpg?alt=media&token=401e3b8a-0f06-412c-b3fd-ed86a87dea60",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "ihUiAYnUZCj3XgVLtIHl",
      updated_at: { seconds: 1685849939, nanoseconds: 864000000 },
      created_at: { seconds: 1685849939, nanoseconds: 864000000 },
      new_notification_id: "9EkDU3eJPuyfXpYl5Ow1",
      status: "ACTIVE",
      majority_option: ["A", 25.18, 171, 0, 171],
      entries: 679,
      minority_option: ["D", 24.89, 169, 0, 169],
      majority_odds: 193,
      all_options_price: [
        {
          total_return: 330.1607910102446,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.18,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 25.04,
          type: "loss",
          option: "B",
        },
        {
          total_return: 315.8392089897553,
          total_amount: 169,
          total_real_users: 0,
          total_bots: 169,
          distribution: 24.89,
          type: "minority",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 1,
          total_bots: 168,
          distribution: 24.89,
          type: "loss",
          option: "C",
        },
      ],
      minority_odds: 187,
    },
    {
      trade_style: "SPLIT",
      question:
        "Which Indian batsman needs to show the most intent in WTC 2023 final regardless of the situation?",
      trade_category: "cricket",
      bot_responses: [
        { amount: 472, number_of_responses: 472, option: "A" },
        { amount: 471, number_of_responses: 471, option: "B" },
        { amount: 470, number_of_responses: 470, option: "C" },
        { amount: 470, number_of_responses: 469, option: "D" },
      ],
      min_amount: 1,
      title: "Intent on the line - WTC final",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686519180, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: "",
          text: "Shubman Gill",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fl1daJFTzdOtFJSxndody%2Foptions%2F0%2Fmedia_400x400.jpg?alt=media&token=d2342cc0-054f-4b19-92d3-952909263e80",
            title: "Gill.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Cheteshwar Pujara",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fl1daJFTzdOtFJSxndody%2Foptions%2F1%2Fmedia_400x400.jpg?alt=media&token=9c86a8d3-dace-4ecb-b1d7-5a8967d36069",
            title: "Pujara.jpg",
          },
        },
        {
          thumbnail: "",
          text: "King Kohli",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fl1daJFTzdOtFJSxndody%2Foptions%2F2%2Fmedia_400x400.jpg?alt=media&token=306cc28d-18b9-4b8b-949e-c44aa14ceb7c",
            title: "Kohli.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Ajinkya Rahane",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fl1daJFTzdOtFJSxndody%2Foptions%2F3%2Fmedia_400x400.jpg?alt=media&token=8fd0b2e6-7263-4b4c-a55c-64bc3ad2f908",
            title: "Rahane.jpg",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "l1daJFTzdOtFJSxndody",
      created_at: { seconds: 1686087409, nanoseconds: 508000000 },
      new_notification_id: "n5FhpVJWQ91pJIaYnySj",
      updated_at: { seconds: 1686087610, nanoseconds: 886000000 },
      status: "ACTIVE",
      majority_option: ["A", 25.07, 472, 0, 472],
      entries: 1883,
      minority_option: ["C", 24.96, 470, 0, 470],
      majority_odds: 183,
      all_options_price: [
        {
          total_return: 862.1838264694197,
          total_amount: 472,
          total_real_users: 0,
          total_bots: 472,
          distribution: 25.07,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 471,
          total_real_users: 0,
          total_bots: 471,
          distribution: 25.01,
          type: "loss",
          option: "B",
        },
        {
          total_return: 936.1661735305803,
          total_amount: 470,
          total_real_users: 0,
          total_bots: 470,
          distribution: 24.96,
          type: "minority",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 480,
          total_real_users: 1,
          total_bots: 469,
          distribution: 24.96,
          type: "loss",
          option: "D",
        },
      ],
      minority_odds: 199,
    },
    {
      trade_style: "SPLIT",
      question:
        "Which Bollywood celebrity would make the most entertaining guest appearance on Indian Big Boss?",
      trade_category: "movie",
      bot_responses: [
        { amount: 420, number_of_responses: 420, option: "A" },
        { amount: 421, number_of_responses: 421, option: "B" },
        { amount: 422, number_of_responses: 422, option: "C" },
        { amount: 419, number_of_responses: 419, option: "D" },
      ],
      min_amount: 1,
      title: "Celeb on Big Boss",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686602940, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: "",
          text: "Raanveer Singh",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fn8oncpyUta4KfiG7DFJE%2Foptions%2F0%2Fmedia.gif?alt=media&token=ca02e3c5-1cd2-4242-aea2-73c3333367cb",
            title: "Ranveer_sq.gif",
          },
        },
        {
          thumbnail: "",
          text: "Kajol",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fn8oncpyUta4KfiG7DFJE%2Foptions%2F1%2Fmedia.gif?alt=media&token=e56b94e9-d69a-4ee0-869a-ca9e79ca6ba9",
            title: "Kajol_sq.gif",
          },
        },
        {
          thumbnail: "",
          text: "Raj kumar Rao",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fn8oncpyUta4KfiG7DFJE%2Foptions%2F2%2Fmedia.gif?alt=media&token=b788ff33-b241-4e2b-bccf-e75afbb3d403",
            title: "Raj_kumar_sq.gif",
          },
        },
        {
          thumbnail: "",
          text: "Kangana Ranaut",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2Fn8oncpyUta4KfiG7DFJE%2Foptions%2F3%2Fmedia.gif?alt=media&token=8ce17a13-b1d2-4ec1-9062-e4580dffc773",
            title: "Kangna_sq.gif",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "n8oncpyUta4KfiG7DFJE",
      created_at: { seconds: 1686084653, nanoseconds: 65000000 },
      new_notification_id: "4efKPtykEJU5V3tfIgUC",
      majority_option: ["C", 25.09, 422, 0, 422],
      entries: 1682,
      minority_option: ["D", 24.91, 419, 0, 419],
      majority_odds: 190,
      all_options_price: [
        {
          total_return: 801.8124199999999,
          total_amount: 422,
          total_real_users: 0,
          total_bots: 422,
          distribution: 25.09,
          type: "majority",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 421,
          total_real_users: 0,
          total_bots: 421,
          distribution: 25.03,
          type: "loss",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 420,
          total_real_users: 0,
          total_bots: 420,
          distribution: 24.97,
          type: "loss",
          option: "A",
        },
        {
          total_return: 796.08758,
          total_amount: 419,
          total_real_users: 0,
          total_bots: 419,
          distribution: 24.91,
          type: "minority",
          option: "D",
        },
      ],
      minority_odds: 190,
      updated_at: { seconds: 1686087631, nanoseconds: 803000000 },
      status: "ACTIVE",
    },
    {
      trade_style: "SPLIT",
      question: "What is the secret ingredient in Mukesh Ambani's morning tea?",
      trade_category: "this_or_that",
      bot_responses: [
        { amount: 170, number_of_responses: 170, option: "A" },
        { amount: 168, number_of_responses: 168, option: "B" },
        { amount: 171, number_of_responses: 171, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Not any morning tea!",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686430620, nanoseconds: 0 },
      question_image: "",
      options: [
        {
          thumbnail: "",
          text: "Unicorn tears",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FoBXxJITDBoVU6GGP0dP1%2Foptions%2F0%2Fmedia_400x400.jpg?alt=media&token=fda2b819-4d2d-467d-9023-8b843cf9023a",
            title: "Unicorn_tears.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Stardust",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FoBXxJITDBoVU6GGP0dP1%2Foptions%2F1%2Fmedia_400x400.jpg?alt=media&token=66043cbf-6b44-4712-a524-a6a985160ad9",
            title: "Stardust.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Liquid gold",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FoBXxJITDBoVU6GGP0dP1%2Foptions%2F2%2Fmedia_400x400.jpg?alt=media&token=0b503eda-6414-4393-bbec-0fb1ba9d700e",
            title: "liquid gold.jpg",
          },
        },
        {
          thumbnail: "",
          text: "Billionaire's brew",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/trades%2FoBXxJITDBoVU6GGP0dP1%2Foptions%2F3%2Fmedia_400x400.jpg?alt=media&token=d38a522d-53ad-44e8-9dd0-28b816803bb8",
            title: "tea_brew.jpg",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "oBXxJITDBoVU6GGP0dP1",
      updated_at: { seconds: 1685853807, nanoseconds: 183000000 },
      created_at: { seconds: 1685853807, nanoseconds: 183000000 },
      new_notification_id: "1ISXrAqK9a7hR0IqkayW",
      status: "ACTIVE",
      majority_option: ["C", 25.26, 204, 1, 171],
      entries: 681,
      minority_option: ["D", 24.82, 169, 0, 169],
      majority_odds: 199,
      all_options_price: [
        {
          total_return: 406.42248832410013,
          total_amount: 204,
          total_real_users: 1,
          total_bots: 171,
          distribution: 25.26,
          type: "majority",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 172,
          total_real_users: 1,
          total_bots: 170,
          distribution: 25.11,
          type: "loss",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 169,
          total_real_users: 1,
          total_bots: 168,
          distribution: 24.82,
          type: "loss",
          option: "B",
        },
        {
          total_return: 271.87751167589994,
          total_amount: 169,
          total_real_users: 0,
          total_bots: 169,
          distribution: 24.82,
          type: "minority",
          option: "D",
        },
      ],
      minority_odds: 161,
    },
    {
      trade_style: "SPLIT",
      question: "Which fictional character is the most relatable?",
      trade_category: "movie",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Like you like me…",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686603420, nanoseconds: 0 },
      options: [
        {
          text: "Geet (Jab We Met)",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FTHF10sS2hajPPpUJpXgC%2Fmedia_400x400.jpg?alt=media&token=f6a14f24-c3e0-48d7-bfe6-2e13d090574a",
            title:
              "media%2FTHF10sS2hajPPpUJpXgC%2Fmedia_400x400.jpg?alt=media&token=f6a14f24-c3e0-48d7-bfe6-2e13d090574a",
          },
        },
        {
          text: "Raj (DDLJ)",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2F9vxa5SVhdfa9VC0qxPG0%2Fmedia_400x400.jpg?alt=media&token=0fb6ce9a-f76c-4026-acf5-af4867569cc9",
            title:
              "media%2F9vxa5SVhdfa9VC0qxPG0%2Fmedia_400x400.jpg?alt=media&token=0fb6ce9a-f76c-4026-acf5-af4867569cc9",
          },
        },
        {
          text: "Sulu (Tumhari Sulu)",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FkovFH7DZG5EwRlVQEGPc%2Fmedia_400x400.jpg?alt=media&token=f831c13c-dc95-4494-959e-56940f3e2a2e",
            title:
              "media%2FkovFH7DZG5EwRlVQEGPc%2Fmedia_400x400.jpg?alt=media&token=f831c13c-dc95-4494-959e-56940f3e2a2e",
          },
        },
        {
          text: "Chulbul Pandey (Dabang)",
          media: {
            src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2Fvd8eW8WwTQin2cUflaq7%2Fmedia_400x400.jpg?alt=media&token=4d358ff2-b398-4ae0-95a8-e9afe4c75c21",
            title:
              "media%2Fvd8eW8WwTQin2cUflaq7%2Fmedia_400x400.jpg?alt=media&token=4d358ff2-b398-4ae0-95a8-e9afe4c75c21",
          },
        },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "s2wZbRFM9fh7wp5bhZie",
      created_at: { seconds: 1686083775, nanoseconds: 338000000 },
      new_notification_id: "4TSd2oHHQr3wvobXSGqG",
      majority_option: ["A", 25.22, 171, 0, 171],
      entries: 678,
      minority_option: ["C", 24.78, 168, 0, 168],
      majority_odds: 190,
      all_options_price: [
        {
          total_return: 324.89244,
          total_amount: 171,
          total_real_users: 0,
          total_bots: 171,
          distribution: 25.22,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 25.07,
          type: "loss",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 169,
          total_real_users: 0,
          total_bots: 169,
          distribution: 24.93,
          type: "loss",
          option: "D",
        },
        {
          total_return: 319.20756000000006,
          total_amount: 168,
          total_real_users: 0,
          total_bots: 168,
          distribution: 24.78,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 190,
      updated_at: { seconds: 1686087633, nanoseconds: 90000000 },
      status: "ACTIVE",
    },
    {
      trade_style: "NOSPLIT",
      question: "What's a get-together with the budies worth?",
      trade_category: "laughter",
      bot_responses: [
        { amount: 170, number_of_responses: 170, option: "A" },
        { amount: 169, number_of_responses: 169, option: "B" },
        { amount: 171, number_of_responses: 171, option: "C" },
        { amount: 168, number_of_responses: 168, option: "D" },
      ],
      min_amount: 1,
      title: "get-together worth it?",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686429840, nanoseconds: 0 },
      question_image: {
        src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2F1Ww7XHwDXdDDkswyzJOh%2Fmedia_400x400.jpg?alt=media&token=18234305-eb9a-448d-befe-f21685c9ad47",
        title:
          "media%2F1Ww7XHwDXdDDkswyzJOh%2Fmedia_400x400.jpg?alt=media&token=18234305-eb9a-448d-befe-f21685c9ad47",
      },
      options: [
        { text: "Bill on you" },
        { text: "A few lies" },
        { text: "Cancel Date night" },
        { text: "A big fight" },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "sc2gzU4VjH5QQ4IdQgYl",
      updated_at: { seconds: 1685849939, nanoseconds: 783000000 },
      created_at: { seconds: 1685849939, nanoseconds: 783000000 },
      new_notification_id: "5Fg3BAleNea7J6KxQfEa",
      status: "ACTIVE",
      majority_option: ["C", 25.26, 173, 1, 171],
      entries: 681,
      minority_option: ["D", 24.82, 218, 1, 168],
      majority_odds: 163,
      all_options_price: [
        {
          total_return: 282.64308347902113,
          total_amount: 173,
          total_real_users: 1,
          total_bots: 171,
          distribution: 25.26,
          type: "majority",
          option: "C",
        },
        {
          total_return: 0,
          total_amount: 209,
          total_real_users: 1,
          total_bots: 169,
          distribution: 24.96,
          type: "loss",
          option: "B",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 24.96,
          type: "loss",
          option: "A",
        },
        {
          total_return: 448.8569165209789,
          total_amount: 218,
          total_real_users: 1,
          total_bots: 168,
          distribution: 24.82,
          type: "minority",
          option: "D",
        },
      ],
      minority_odds: 206,
    },
    {
      trade_style: "NOSPLIT",
      question:
        "Which Bollywood celebrity would make the most entertaining guest appearance on Indian Big Boss?",
      bot_responses: [],
      trade_category: "movie",
      min_amount: 1,
      title: "Celeb on Big Boss",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686391020, nanoseconds: 0 },
      question_image: "",
      options: [
        { thumbnail: "", text: "Raanveer Singh", media: "" },
        { thumbnail: "", text: "Kajol", media: "" },
        { thumbnail: "", text: "Raj kumar Rao", media: "" },
        { thumbnail: "", text: "Kangana Ranaut", media: "" },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "t9iik0MLvIcZEZQtFRv4",
      created_at: { seconds: 1685959077, nanoseconds: 809000000 },
      new_notification_id: "JZJ7qFpC1LzOWqBANpJZ",
      updated_at: { seconds: 1685959301, nanoseconds: 92000000 },
      status: "ACTIVE",
      majority_option: ["A", 38.46, 14, 1, 4],
      entries: 13,
      minority_option: ["C", 15.38, 2, 0, 2],
      majority_odds: 308,
      all_options_price: [
        {
          total_return: 43.13128775204323,
          total_amount: 14,
          total_real_users: 1,
          total_bots: 4,
          distribution: 38.46,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 53,
          total_real_users: 2,
          total_bots: 1,
          distribution: 23.08,
          type: "loss",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 3,
          total_real_users: 0,
          total_bots: 3,
          distribution: 23.08,
          type: "loss",
          option: "B",
        },
        {
          total_return: 25.268712247956778,
          total_amount: 2,
          total_real_users: 0,
          total_bots: 2,
          distribution: 15.38,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 1263,
    },
    {
      trade_style: "NOSPLIT",
      question:
        "What would you name the wi-fi connection, if it were for a charity?",
      trade_category: "laughter",
      bot_responses: [
        { amount: 171, number_of_responses: 171, option: "A" },
        { amount: 170, number_of_responses: 170, option: "B" },
        { amount: 168, number_of_responses: 168, option: "C" },
        { amount: 169, number_of_responses: 169, option: "D" },
      ],
      min_amount: 1,
      title: "Wi-fi beyond connections",
      created_by: "admin@qunions.com",
      expire_on: { seconds: 1686429840, nanoseconds: 0 },
      question_image: {
        src: "https://firebasestorage.googleapis.com/v0/b/qunionsapp-f1682.appspot.com/o/media%2FO5W6LhO4w1CLiRrAgxZl%2Fmedia_400x400.jpg?alt=media&token=17f8e636-a285-4daf-94d3-8bda7c798ec1",
        title:
          "media%2FO5W6LhO4w1CLiRrAgxZl%2Fmedia_400x400.jpg?alt=media&token=17f8e636-a285-4daf-94d3-8bda7c798ec1",
      },
      options: [
        { text: "The Promised LAN" },
        { text: "Game of Phones" },
        { text: "8Hz WAN IP" },
        { text: "Loading…" },
      ],
      updated_by: "admin@qunions.com",
      max_amount: 50,
      id: "zHjOIAeeJTJrkQ4C6No9",
      updated_at: { seconds: 1685849939, nanoseconds: 922000000 },
      created_at: { seconds: 1685849939, nanoseconds: 922000000 },
      new_notification_id: "IYrmtCOFpEWTHXp69jfy",
      status: "ACTIVE",
      majority_option: ["A", 25.26, 203, 1, 171],
      entries: 681,
      minority_option: ["C", 24.82, 170, 1, 168],
      majority_odds: 148,
      all_options_price: [
        {
          total_return: 301.00256228146867,
          total_amount: 203,
          total_real_users: 1,
          total_bots: 171,
          distribution: 25.26,
          type: "majority",
          option: "A",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 1,
          total_bots: 169,
          distribution: 24.96,
          type: "loss",
          option: "D",
        },
        {
          total_return: 0,
          total_amount: 170,
          total_real_users: 0,
          total_bots: 170,
          distribution: 24.96,
          type: "loss",
          option: "B",
        },
        {
          total_return: 376.3474377185314,
          total_amount: 170,
          total_real_users: 1,
          total_bots: 168,
          distribution: 24.82,
          type: "minority",
          option: "C",
        },
      ],
      minority_odds: 221,
    },
  ];
  const tradesCol = collection(db, "trades");
  const _query = query(tradesCol, where("status", "==", "ACTIVE"));
  const tradeSnapshot = await getDocs(_query);
  const tradeList = tradeSnapshot.docs
    .map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    .filter((tr) => !tr.private_trade);
  console.log(tradeList.length);
  return tradeList.length > 2 ? tradeList : defaultTrades;
};
