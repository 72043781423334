import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { app } from "../../../constants";

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const scrollWithOffset = (el) =>
    window.scrollTo({
      top: el.getBoundingClientRect().top + window.pageYOffset - 50,
      behavior: "smooth",
    });
  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${
            darkBg ? "bg-transparent" : "custom-nav white-bg"
          } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <img
                  src={`${app.url}assets/img/logo-white-1x.png`}
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/logo-color-1x.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    smooth
                    to="/#home"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    Home
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    smooth
                    to="#league"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    League
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    smooth
                    to="#trades"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    Trades
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    smooth
                    to="#features"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    Features
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    smooth
                    to="#screenshots"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    Screenshots
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    smooth
                    to="#team"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    Team
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    smooth
                    to="#contact"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
