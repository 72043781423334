import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { getTrades } from "../../database";
import { useNavigate } from "react-router-dom";

import { formEntries, formExpireTime } from "../../utils";
import { app } from "../../constants";

const OpenTrades = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [trades, setTrades] = useState([]);

  const Option = ({ question_image, option, index, media, style }) => {
    return (
      <div
        className={`pr-0 col-12 mt-3 d-flex ${
          question_image ? "" : "col-md-6"
        }`}
        style={{
          minHeight: 50,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #CECECE",
            borderRadius: 8,
            flex: 1,
            overflow: "hidden",
          }}
        >
          {media?.src && !question_image ? (
            <div
              style={{
                width: 100,
                height: 100,
                backgroundImage: `url(${media.src})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                ...(style == "SPLIT" && { width: 150, height: 150 }),
              }}
            ></div>
          ) : (
            <div
              className="h-100 d-flex align-items-center"
              style={{
                backgroundColor: app.colors.primary,
              }}
            >
              <p
                className="mb-0 color-white text-bold"
                style={{
                  width: 50,
                  textAlign: "center",
                }}
              >
                {app.option_mappings[index]}
              </p>
            </div>
          )}
          <div
            style={{
              borderLeft: "1px solid #CECECE",
              padding: "5px 10px",
            }}
            className="h-100 d-flex align-items-center"
          >
            <p
              className="mb-0 color-black"
              style={{
                lineHeight: 1.5,
              }}
            >
              {option}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const Trade = ({ trade }) => {
    const question_image = trade?.question_image
      ? trade?.question_image.src
      : "";
    return (
      <div className="col-12 d-flex" style={{ flex: 1 }}>
        <div
          style={{
            borderRadius: 15,
            justifyContent: "center",
            border: "1px solid black",
          }}
          className="d-flex py-3 w-100"
        >
          <div
            style={{
              borderRadius: "0.5rem",
              padding: 20,
              paddingTop: 10,
            }}
            className="w-100"
          >
            <div className="row">
              <div className="col-12 col-sm-8">
                <h5 className="mb-0 color-black">{trade.title}</h5>
              </div>
              <div className="col-12 col-sm-2 color-black">
                <div>
                  <strong>Entries:</strong> {formEntries(trade.entries)}
                </div>
              </div>
              <div className="col-12 col-sm-2 color-black">
                <div>
                  <strong>Active:</strong>{" "}
                  {formExpireTime(trade.expire_on.seconds)}
                </div>
              </div>
            </div>
            <h6 className="mt-3 text-black mb-3 text-bold">{trade.question}</h6>
            <div className="row mb-5">
              {question_image && (
                <div className="col-12 col-md-6">
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url(${question_image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      borderRadius: 8,
                    }}
                    className="question-image-height"
                  ></div>
                </div>
              )}
              <div
                className={`col-12 ${
                  question_image ? "col-md-6" : ""
                } row pr-0`}
              >
                {trade.options.map((option, j) => (
                  <Option
                    option={typeof option == typeof "" ? option : option.text}
                    media={typeof option == typeof "" ? "" : option?.media}
                    style={trade.trade_style}
                    question_image={question_image}
                    key={j}
                    index={j}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <center>
            <button
              className="btn solid-btn mt-3 mb-3 text-black"
              onClick={() => navigate("/download")}
              style={{
                backgroundColor: "#F2AE00",
                borderColor: "#F2AE00",
              }}
            >
              Play Now
            </button>
          </center>
        </div>
      </div>
    );
  };
  const laodTrades = async () => {
    setLoading(true);
    let trades = await getTrades();
    // try {
    //   trades = trades.filter((trade) => !trade.options[0].media);
    // } catch (error) {}
    setTrades(
      trades.sort((a, b) => {
        if (a.expire_on.seconds < b.expire_on.seconds) {
          return -1;
        } else if (a.expire_on.seconds > b.expire_on.seconds) {
          return 1;
        } else {
          return 0;
        }
      })
    );
    setLoading(false);
  };
  useEffect(() => {
    laodTrades();
  }, []);
  return (
    <>
      <section id="trades" className="about-us py-5">
        <div className="container">
          <h2 className="text-center mb-5">Open Trades</h2>
          <div className="row">
            {loading ? (
              <div className="col-12">
                <div className="d-flex justify-content-center ">
                  <div className="spinner-border color-primary"></div>
                </div>
              </div>
            ) : (
              <OwlCarousel
                className="screen-carousel owl-carousel owl-theme dot-indicator"
                {...app.owl_options()}
              >
                {trades.map((trade, index) => (
                  <Trade key={index} trade={trade} index={index} />
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default OpenTrades;
