import React from "react";
import { app } from "../../constants";

export default function Counter({ className }) {
  return (
    <>
      <div className={`counter ${className}`}>
        <div className="container">
          <div className="row">
            <ul className="list-inline counter-wrap">
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>∞</span>
                  <h6>Happy Client</h6>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>100K+</span>
                  <h6>App Download</h6>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>7K+</span>
                  <h6>Total Trades</h6>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>{app.currency} 9L+</span>
                  <h6>Amount Dispatched</h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
