import React from "react";
import { Routes, Route } from "react-router-dom";
import ComingSoon from "../components/others/ComingSoon";
import NotFound from "../components/others/NotFound";
import ThankYou from "../components/others/ThankYou";
import HomeTwo from "../themes/index-2/HomeTwo";
import Download from "../pages/Download";
import Review from "../pages/Review";
import Faq from "../pages/Faq";
import ScrollToTop from "./ScrollToTop";
import TermAndCondition from "../pages/TermAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TACnPP from "../pages/TACnPP";
import ReferralStats from "../pages/ReferralStats";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeTwo />} />
        <Route path="/contest" element={<HomeTwo />} />
        <Route path="/term-and-conditions" element={<TermAndCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/tac-and-pp" element={<TACnPP />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/download" element={<Download />} />
        <Route path="/referral/:ambassador_id" element={<ReferralStats />} />
        <Route path="/review" element={<Review />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
