import React from "react";
import Counter from "../components/counter/Counter";
import DownloadCta from "../components/cta/DownloadCta";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import ReferralStatsTable from "../components/ReferralStatsTable";

export default function ReferralStats() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader PageTitle="Download" hideBread />
      <Counter className="mt--120" />
      <ReferralStatsTable />
      <Footer />
    </Layout>
  );
}
