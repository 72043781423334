import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { app } from "../constants";
import { getCollectionData } from "../database";
import { where } from "firebase/firestore/lite";

export default function ReferralStatsTable() {
  const { ambassador_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [ambassador, setAmbassador] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        const ambassadors = await getCollectionData("ambassadors", [
          where("referral_code", "==", ambassador_id),
        ]);
        if (ambassadors.length > 0) {
          const _ambassador = ambassadors[0];
          if (!_ambassador) throw "";
          setAmbassador(_ambassador);
        } else throw "";
      } catch (error) {
        window.location.href = app.url;
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <>
      <section id="download" className="video-promo py-5">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12">
              <div className="download-content">
                <h2 className="mt-5">Ambassadors Statistics</h2>
              </div>
            </div>
            {loading ? (
              <div className="col-12">
                <center>
                  <div
                    className="spinner-border mt-4"
                    style={{
                      color: app.colors.primary,
                    }}
                  ></div>
                </center>
              </div>
            ) : (
              <>
                <div className="col-12">
                  <table className="table w-100">
                    <tr>
                      <th className="text-bold">Name</th>
                      <td>{ambassador.name}</td>
                    </tr>
                    <tr>
                      <th className="text-bold">Referral Code</th>
                      <td>{ambassador.referral_code}</td>
                    </tr>
                    <tr>
                      <th className="text-bold">Referral Link Opened</th>
                      <td>{ambassador?.referral_link_opened || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-bold">Total Users</th>
                      <td>{(ambassador.users || []).length}</td>
                    </tr>
                  </table>
                </div>
                {ambassador?.stats && (
                  <div className="col-12">
                    <h4>Users Statistics</h4>
                    <table className="table w-100">
                      <thead>
                        <tr>
                          <th className="text-bold">User Id</th>
                          <th className="text-bold">Total Trades</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(ambassador.stats || []).map((userId) => (
                          <tr>
                            <td>{userId}</td>
                            <td>{ambassador.stats[userId].total_trades}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
