export default function AccordionWithImg() {
  const faqs = [
    {
      title: "What is Qunions?",
      description:
        "Qunions – Questions n Opinions, is a questions and opinions platform, where there are only opinions to questions – no factual one correct answer. All options are just different opinions to a question/issue. 2 out of all options are winning options. The option/opinion that is selected the most and the option/opinion that is selected the least are winning options. Choose an opinion that you think/analyze would be most popular or most unique in your group or in an open event. There are 3 different Qunions format – Trades, Events and Game. In different formats, there are different implementations of the above stated core concept. Strategize as per the format’s winning objective to win.",
    },
    {
      title: "What are Qunions Open Trades?",
      description:
        "Qunions Open Trades are a format of Qunions. Here, questions are launched about a recently concluded event analyzing that event or on any issue/theme that is purely opinion-based. Users can select an opinion from a set of different opinions and invest in that option within a minimum and maximum range for that trade. For all trades question, there are only opinions – no factual one correct answer! Two out of all options in any/all trades are winning options Choose an opinion that you think/analyze would be the most popular (Majority) or the most unique (Minority) for that question. If your chosen opinion is in Majority or Minority, you will win as per Majority return% or Minority return% on your investment for that trade. Remember, a Trade is only active (open) for a certain duration, so be sure to chip in with your opinion before it is closed. Once the Trade is closed, results are declared on the App.",
    },
    {
      title: "How are winning returns determined?",
      description:
        "Winning return percentages of Majority or Minority winning categories are calculated and displayed live on the App with some lag. The returns are based on the difficulty of identifying any winning category in the population. Lower separability corresponds to higher difficulty and in turn, higher returns. For example, if users have selected a particular option significantly more than the rest of the options then the return on “Majority” winning category will decrease. And if there is not much difference between the most selected option and the 2nd most selected option then the return on “Majority” winning category will increase. Same logic flows for “Minority” winning category returns.",
    },
    {
      title: "Is Opinion Trading done on real Money on the Platform?",
      description:
        "No. Currently, we are offering risk-free trading with virtual currency called rewards money. However, your rewards can be redeemed into real cash prizes. You can make eligible recharges of rewards balance for free unlimited times.",
    },
    {
      title: "How much “rewards money” can I recharge for free?",
      description:
        "Unlimited. If your rewards balance drops down to under 500, you can recharge it up to get to 500 for free. You can keep losing on trades and keep recharging. But if you win, you can avail real cash prizes from your rewards money.",
    },
    {
      title: "Can I ask/convince other people to Vote the same as I did?",
      description:
        "However, it’s not encouraged but it’s not against any rules or it does not constitute violation of any terms and conditions. To keep up with the spirit of the game, we suggest you to keep your opinions private and let the results give you the thrill of your thinking on a topic compared to others.",
    },
    {
      title:
        "Can I still win if my chosen option is selected the least in a Trade?",
      description:
        "Yes. That’s one of the winning goals. If your selected option is selected the most or the least, you win. So, choose an option/opinion that you think/analyze would be most popular or most unique in your group or in an open event. If your chosen opinion is in Majority or Minority, you will win as per Majority return% or Minority return% on your investment for that trade.",
    },
    {
      title: "Does the App ask for any payment method?",
      description:
        "No. It’s all completely free. Best part, not even Ads. Just expect to win some real cash and enjoy the experience at Qunions while you are at it.",
    },
  ];
  return (
    <>
      <section
        className="download-section py-5 background-img"
        style={{
          background:
            "url('assets/img/app-hero-bg.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12">
              <h2 className="text-center color-white mb-5">FAQs</h2>
            </div>
            <div className="col-lg-6">
              <div id="accordion-1" className="accordion accordion-faq pb-100">
                {faqs.map((faq, index) => (
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id={"heading-" + index}
                      data-toggle="collapse"
                      role="button"
                      data-target={"#collapse-" + index}
                      aria-expanded="false"
                      aria-controls={"collapse-" + index}
                    >
                      <h6 className="mb-0 pr-4">
                        <span className="ti-receipt mr-3"></span> {faq.title}
                      </h6>
                    </div>
                    <div
                      id={"collapse-" + index}
                      className={"collapse"}
                      aria-labelledby={"heading-" + index}
                      data-parent={"#accordion-1"}
                    >
                      <div className="card-body">
                        <p>{faq.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end">
                <img
                  src="assets/img/image-13-new.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
