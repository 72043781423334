import React, { useEffect } from "react";
import { increment } from "firebase/firestore/lite";
import { addDocToCollection, updateDocFromCollection } from "../../database";
import axios from "axios";

export default function DownloadCta() {
  useEffect(() => {
    (async () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isAndroid = /android/i.test(userAgent);
      const isApple = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      try {
        let referral_code = new URL(window.location.href).searchParams.get(
          "referral"
        );
        if (referral_code) {
          const apiUrl = "https://api.ipify.org/?format=json";

          const { data } = await axios.get(apiUrl);
          const ip_address = data?.ip;
          if (ip_address) {
            await addDocToCollection("ip_address_mappings", {
              ip_address,
              referral_code,
            });
            await updateDocFromCollection("ambassadors", referral_code, {
              referral_link_opened: increment(1),
            });
          }
        }
      } catch (error) {
      } finally {
        if (isAndroid)
          window.location.href =
            "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.app.qunions";
        if (isApple)
          window.location.href =
            "itms-apps://itunes.apple.com/app/id1668454446";
      }
    })();
  }, []);
  return (
    <>
      <section id="download" className="video-promo py-5">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content">
                <p
                  style={{
                    fontSize: "2.6em",
                    color: "black",
                    fontFamily: '"Montserrat", sans-serif',
                    marginTop: 20,
                  }}
                  className="mb-3"
                >
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Qunions
                  </span>{" "}
                  &ndash;{" "}
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Qu
                  </span>
                  estions & Opi
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    nions
                  </span>
                </p>
                <p>
                  World's 1st Opinion poll Contests App. Play Contests using
                  your IQ and EQ to win exciting rewards! Strategic opinions,
                  real winnings! Choose the most popular or the most unique
                  opinion.
                </p>
                <p>
                  Welcome to “Qunions” – Questions & Opinions, where you can
                  enjoy your favorite content with a novel and gratifying
                  approach, nurturing critical thinking rather than succumbing
                  to mindless swiping and external influences. Qunions is a
                  Questions and Opinions platform, where contests are conducted
                  across genres – Sports, Laughter, Movies, Trends, Politics,
                  Office, etc. Here, there are only opinions to questions – no
                  factual one correct answer! Choose an opinion that you
                  think/analyze would be most.
                </p>
                <div className="download-btn mt-5 pt-2 d-flex justify-content-between">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.app.qunions&hl=en&gl=US"
                    className="btn solid-btn"
                    target="_blank"
                  >
                    <span className="ti-android"></span> Google Play
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/apple-store/id1668454446"
                    className="btn solid-btn"
                    target="_blank"
                  >
                    <span className="ti-apple"></span> App Store
                  </a>
                  <a
                    href="https://expo.dev/artifacts/eas/4GYgutEdEP9dqvFuhE8RE9.apk"
                    className="btn outline-btn"
                    download
                  >
                    <span className="ti-android"></span> APK Download
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="download-img mt-lg-5 mt-md-5 mt-sm-0 d-flex justify-content-center">
                <img
                  src="assets/img/image-14-1.png"
                  alt="download"
                  className="img-fluid w-75"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
