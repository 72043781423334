import React from "react";
import Counter from "../../components/counter/Counter";
import OwlCarousel from "react-owl-carousel";
import { app } from "../../constants";

export default function HeroTwo() {
  const data = [
    ["./assets/img/hero/1.png", "./assets/img/hero/ss1.png"],
    ["./assets/img/hero/2.png", "./assets/img/hero/ss2.png"],
    ["./assets/img/hero/3.png", "./assets/img/hero/ss3.png"],
    ["./assets/img/hero/4.png", "./assets/img/hero/ss4.png"],
    ["./assets/img/hero/5.png", "./assets/img/hero/ss5.png"],
    ["./assets/img/hero/2.png", "./assets/img/hero/ss6.png"],
    ["./assets/img/hero/1.png", "./assets/img/hero/ss7.png"],
    ["./assets/img/hero/4.png", "./assets/img/hero/ss8.png"],
  ];
  const Item = ({ item }) => {
    return (
      <div className="row align-items-center">
        <div className="col-6 d-flex justify-content-center">
          <img src={item[0]} className="no-border" />
        </div>
        <div className="col-6 d-flex justify-content-center">
          <div className="w-75">
            <img src={item[1]} className="no-border" />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <section
        className="hero-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/hero-bg-1.jpg')no-repeat center center / cover",
        }}
        id="home"
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-7">
              <div className="hero-content-left text-white mt-5">
                <h1
                  className="text-white"
                  style={{
                    fontSize: 55,
                  }}
                >
                  <span> Qunions</span> &ndash; Questions and Opinions
                </h1>
                <p className="lead" style={{ marginTop: -10 }}>
                  Strategic opinions, real winnings! Be the most popular or the
                  most unique in your opinions.
                </p>
                <div className="download-btn" style={{ marginTop: "-1rem" }}>
                  <button
                    className="btn google-play-btn mt-3 mr-3"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.app.qunions&hl=en&gl=US"
                      )
                    }
                  >
                    <span className="ti-android"></span> Google Play
                  </button>
                  <button
                    className="btn google-play-btn color-white bg-white mt-3 mr-3"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/apple-store/id1668454446"
                      )
                    }
                  >
                    <span className="ti-apple"></span> App Store
                  </button>
                  <button
                    className="btn app-store-btn mt-3"
                    onClick={() =>
                      window.open(
                        "https://expo.dev/artifacts/eas/4GYgutEdEP9dqvFuhE8RE9.apk"
                      )
                    }
                    style={{
                      color: "#FFF !important",
                    }}
                  >
                    <span className="ti-android"></span> APK Download
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-5">
              <OwlCarousel
                className="screen-carousel owl-carousel owl-theme dot-indicator"
                {...app.owl_options()}
              >
                {data.map((item) => (
                  <Item item={item} />
                ))}
              </OwlCarousel>
            </div>
          </div>
          <Counter />
        </div>
      </section>
    </>
  );
}
