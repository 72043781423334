import React from "react";

export default function FeatureImg({ ImgSource }) {
  const contents = [
    {
      title: "Trading as per population voting.",
      icon: "1.png",
      bg_color: "#FBE4D6",
    },
    { title: "No right or wrong answer.", icon: "2.png", bg_color: "#DBE3F2" },
    {
      title: "Majority and Minority wins.",
      icon: "3.png",
      bg_color: "#FFF2CC",
    },
    {
      title: "Edit before close unlimited times.",
      icon: "4.png",
      bg_color: "#E1F0D9",
    },
    {
      title: "100% transparency with total users.",
      icon: "5.png",
      bg_color: "#CFF1FD",
    },
    {
      title: "Real time winning returns displayed.",
      icon: "6.png",
      bg_color: "#FBE4D6",
    },
  ];
  return (
    <>
      <div id="features" className="feature-section py-5 gray-light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>Best Features</h2>
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-8">
              {contents.map((content) => (
                <div className="d-flex align-items-center mb-5">
                  <div className="pr-4">
                    <div
                      className="rounded-circle"
                      style={{
                        backgroundColor: content.bg_color,
                        width: 70,
                        height: 70,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`../assets/img/benefits/${content.icon}`}
                        className="img-fluid"
                        style={{
                          width: 45,
                          height: 45,
                        }}
                      />
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5 className="mb-0">{content.title}</h5>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-4">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img
                  alt="placeholder"
                  src={ImgSource}
                  className="img-center img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
