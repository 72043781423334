import React from "react";

export default function Rules() {
  const rules = [
    "Post-event analysis contests are published daily after each match. Users play for free and increase their league rating by strategically winning contests. The top performers of the league win real cash prizes!",
    "In a contest, 2 out of 4 options end up being the winning options – the most popular one (Majority) and the most unique one (Minority). Each participating user selects one option, and all votes for each option are counted. The options with the most and least votes are declared the winners.",
    "The returns on either winning objective are determined based on the difficulty of identifying that objective within the population. The higher the separation, the lower the returns, and vice versa.",
    "The data on current winning returns and the number of entries are published with some lag. Users can modify their responses at any time (with no limit) until the close, based on the latest statistics. The most recent response before the close is considered final.",
    "Play contests strategically for either the Majority or Minority objective - aim for the higher winning returns objective if you are confident, or else opt for a safer bet! The winning returns tally up for all contests in a league. The top 8 highest-rating users win real cash prizes!",
  ];
  return (
    <>
      <section
        id="league"
        className="promo-section mt-5 pb-5 pt-5 gray-light-bg"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Qunions League</h2>
              <ul className="list-unstyled mt-4">
                {rules.map((rule, index) => (
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0" style={{ textAlign: "justify" }}>
                          {rule}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="d-flex justify-content-center">
                <img
                  src="assets/img/podium.png"
                  className="img-fluid mt-4"
                  style={{ width: 500 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
