const __DEV__ = ["localhost", "dev-qunions.web.app"].includes(
  window.location.hostname
);
export const app = {
  colors: {
    primary: "#8922B8",
  },
  currency: "₹",
  option_mappings: { 0: "A", 1: "B", 2: "C", 3: "D", 4: "E" },
  firebaseConfig: __DEV__
    ? {
        apiKey: "AIzaSyAiLyfpmXw90hn_jPcUFlcerCF4lEaTNKs",
        authDomain: "dev-qunions.firebaseapp.com",
        databaseURL: "https://dev-qunions-default-rtdb.firebaseio.com",
        projectId: "dev-qunions",
        storageBucket: "dev-qunions.appspot.com",
        messagingSenderId: "188280089683",
        appId: "1:188280089683:web:110183dfc482245b502aa6",
      }
    : {
        apiKey: "AIzaSyC-88bnPo_rgDkXmoOng-Pq67w1S7ED-wg",
        authDomain: "qunionsapp-f1682.firebaseapp.com",
        databaseURL: "https://qunionsapp-f1682-default-rtdb.firebaseio.com",
        projectId: "qunionsapp-f1682",
        storageBucket: "qunionsapp-f1682.appspot.com",
        messagingSenderId: "506270410265",
        appId: "1:506270410265:web:ee2c7a0ae802c0800f6c88",
      },
  __DEV__: __DEV__,
  url: `${window.location.origin}/`,
  owl_options: (override = {}) => {
    return {
      loop: true,
      margin: 0,
      center: true,
      dots: true,
      nav: false,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 1,
        },
        991: {
          items: 1,
        },
        1200: {
          items: 1,
        },
        1920: {
          items: 1,
        },
      },
      ...override,
    };
  },
};
